import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    :root {
        --color-primary: #5C2576;
        --color-white: #FFFFFF;
    }
    .color-primary {
        color: var(--color-primary);
    }
`

export default GlobalStyles
