import { Link } from 'react-router-dom'
import { PageTitleBar } from '../../components/styles'
import BtnBack from '../../assets/img/btnBack.svg'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ToolPin from '../../assets/img/drawing/pin.svg'
import ToolSelect from '../../assets/img/drawing/select.svg'
import ToolCloth from '../../assets/img/drawing/put.svg'
import ToolDraw from '../../assets/img/drawing/draw.svg'
import ToolShot from '../../assets/img/drawing/shot.svg'
import { alertModal } from '../../components/AlertModal'

import I0002 from '../../assets/img/shots/0002.png'
import I0003 from '../../assets/img/shots/0003.png'
import I0004 from '../../assets/img/shots/0004.png'
import I0074 from '../../assets/img/shots/0074.png'
import I0076 from '../../assets/img/shots/0076.png'
import I0079 from '../../assets/img/shots/0079.png'
import I0080 from '../../assets/img/shots/0080.png'
import I0085 from '../../assets/img/shots/0085.png'
import I0089 from '../../assets/img/shots/0089.png'
import I0094 from '../../assets/img/shots/0094.png'
import I0105 from '../../assets/img/shots/0105.png'
import I0114 from '../../assets/img/shots/0114.png'
import I0119 from '../../assets/img/shots/0119.png'
import I0120 from '../../assets/img/shots/0120.png'
import I0130 from '../../assets/img/shots/0130.png'
import I0140 from '../../assets/img/shots/0140.png'

// one-piece long black mesh
import olbm01 from '../../assets/img/res/one-piece/long/black/mesh/001.png'
import olbm02 from '../../assets/img/res/one-piece/long/black/mesh/002.png'
import olbm03 from '../../assets/img/res/one-piece/long/black/mesh/003.png'

// one-piece long black off shoulder
import olbo01 from '../../assets/img/res/one-piece/long/black/off_shoulder/001.png'
import olbo02 from '../../assets/img/res/one-piece/long/black/off_shoulder/002.png'
import olbo03 from '../../assets/img/res/one-piece/long/black/off_shoulder/003.png'
import olbo04 from '../../assets/img/res/one-piece/long/black/off_shoulder/004.png'
import olbo05 from '../../assets/img/res/one-piece/long/black/off_shoulder/005.png'
import olbo06 from '../../assets/img/res/one-piece/long/black/off_shoulder/006.png'
import olbo07 from '../../assets/img/res/one-piece/long/black/off_shoulder/007.png'
import olbo08 from '../../assets/img/res/one-piece/long/black/off_shoulder/008.png'
import olbo09 from '../../assets/img/res/one-piece/long/black/off_shoulder/009.png'
import olbo10 from '../../assets/img/res/one-piece/long/black/off_shoulder/010.png'
import olbo11 from '../../assets/img/res/one-piece/long/black/off_shoulder/011.png'
import olbo12 from '../../assets/img/res/one-piece/long/black/off_shoulder/012.png'
import olbo13 from '../../assets/img/res/one-piece/long/black/off_shoulder/013.png'

// one-piece long white mesh
import olwm01 from '../../assets/img/res/one-piece/long/white/mesh/001.png'

// one-piece long white off shoulder
import olwo01 from '../../assets/img/res/one-piece/long/white/off_shoulder/001.png'
import olwo02 from '../../assets/img/res/one-piece/long/white/off_shoulder/002.png'
import olwo03 from '../../assets/img/res/one-piece/long/white/off_shoulder/003.png'
import olwo04 from '../../assets/img/res/one-piece/long/white/off_shoulder/004.png'
import olwo05 from '../../assets/img/res/one-piece/long/white/off_shoulder/005.png'
import olwo06 from '../../assets/img/res/one-piece/long/white/off_shoulder/006.png'
import olwo07 from '../../assets/img/res/one-piece/long/white/off_shoulder/007.png'
import olwo08 from '../../assets/img/res/one-piece/long/white/off_shoulder/008.png'
import olwo09 from '../../assets/img/res/one-piece/long/white/off_shoulder/009.png'
import olwo10 from '../../assets/img/res/one-piece/long/white/off_shoulder/010.png'
import olwo11 from '../../assets/img/res/one-piece/long/white/off_shoulder/011.png'
import olwo12 from '../../assets/img/res/one-piece/long/white/off_shoulder/012.png'
import olwo13 from '../../assets/img/res/one-piece/long/white/off_shoulder/013.png'
import olwo14 from '../../assets/img/res/one-piece/long/white/off_shoulder/014.png'

// one-piece short black mesh
import osbm01 from '../../assets/img/res/one-piece/short/black/mesh/001.png'
import osbm02 from '../../assets/img/res/one-piece/short/black/mesh/002.png'
import osbm03 from '../../assets/img/res/one-piece/short/black/mesh/003.png'
import osbm04 from '../../assets/img/res/one-piece/short/black/mesh/004.png'
import osbm05 from '../../assets/img/res/one-piece/short/black/mesh/005.png'
import osbm06 from '../../assets/img/res/one-piece/short/black/mesh/006.png'
import osbm07 from '../../assets/img/res/one-piece/short/black/mesh/007.png'
import osbm08 from '../../assets/img/res/one-piece/short/black/mesh/008.png'

// one-piece short black off shoulder
import osbo01 from '../../assets/img/res/one-piece/short/black/off_shoulder/001.png'
import osbo02 from '../../assets/img/res/one-piece/short/black/off_shoulder/002.png'
import osbo03 from '../../assets/img/res/one-piece/short/black/off_shoulder/003.png'
import osbo04 from '../../assets/img/res/one-piece/short/black/off_shoulder/004.png'
import osbo05 from '../../assets/img/res/one-piece/short/black/off_shoulder/005.png'
import osbo06 from '../../assets/img/res/one-piece/short/black/off_shoulder/006.png'
import osbo07 from '../../assets/img/res/one-piece/short/black/off_shoulder/007.png'
import osbo08 from '../../assets/img/res/one-piece/short/black/off_shoulder/008.png'
import osbo09 from '../../assets/img/res/one-piece/short/black/off_shoulder/009.png'
import osbo10 from '../../assets/img/res/one-piece/short/black/off_shoulder/010.png'
import osbo11 from '../../assets/img/res/one-piece/short/black/off_shoulder/011.png'

// one-piece short white mesh
import oswm01 from '../../assets/img/res/one-piece/short/white/mesh/001.png'
import oswm02 from '../../assets/img/res/one-piece/short/white/mesh/002.png'
import oswm03 from '../../assets/img/res/one-piece/short/white/mesh/003.png'
import oswm04 from '../../assets/img/res/one-piece/short/white/mesh/004.png'
import oswm05 from '../../assets/img/res/one-piece/short/white/mesh/005.png'
import oswm06 from '../../assets/img/res/one-piece/short/white/mesh/006.png'
import oswm07 from '../../assets/img/res/one-piece/short/white/mesh/007.png'
import oswm08 from '../../assets/img/res/one-piece/short/white/mesh/008.png'
import oswm09 from '../../assets/img/res/one-piece/short/white/mesh/009.png'
import oswm10 from '../../assets/img/res/one-piece/short/white/mesh/010.png'

// one-piece short white off shoulder
import oswo01 from '../../assets/img/res/one-piece/short/white/off_shoulder/001.png'
import oswo02 from '../../assets/img/res/one-piece/short/white/off_shoulder/002.png'
import oswo03 from '../../assets/img/res/one-piece/short/white/off_shoulder/003.png'
import oswo04 from '../../assets/img/res/one-piece/short/white/off_shoulder/004.png'
import oswo05 from '../../assets/img/res/one-piece/short/white/off_shoulder/005.png'
import oswo06 from '../../assets/img/res/one-piece/short/white/off_shoulder/006.png'
import oswo07 from '../../assets/img/res/one-piece/short/white/off_shoulder/007.png'
import oswo08 from '../../assets/img/res/one-piece/short/white/off_shoulder/008.png'
import oswo09 from '../../assets/img/res/one-piece/short/white/off_shoulder/009.png'
import oswo10 from '../../assets/img/res/one-piece/short/white/off_shoulder/010.png'

// two-piece pants casual long
import tpcl01 from '../../assets/img/res/two-piece/pants/casual/long/001.png'
import tpcl02 from '../../assets/img/res/two-piece/pants/casual/long/002.png'
import tpcl03 from '../../assets/img/res/two-piece/pants/casual/long/003.png'
import tpcl04 from '../../assets/img/res/two-piece/pants/casual/long/004.png'
import tpcl05 from '../../assets/img/res/two-piece/pants/casual/long/005.png'
import tpcl06 from '../../assets/img/res/two-piece/pants/casual/long/006.png'
import tpcl07 from '../../assets/img/res/two-piece/pants/casual/long/007.png'
import tpcl08 from '../../assets/img/res/two-piece/pants/casual/long/008.png'
import tpcl09 from '../../assets/img/res/two-piece/pants/casual/long/009.png'
import tpcl10 from '../../assets/img/res/two-piece/pants/casual/long/010.png'
import tpcl11 from '../../assets/img/res/two-piece/pants/casual/long/011.png'
import tpcl12 from '../../assets/img/res/two-piece/pants/casual/long/012.png'
import tpcl13 from '../../assets/img/res/two-piece/pants/casual/long/013.png'
import tpcl14 from '../../assets/img/res/two-piece/pants/casual/long/014.png'
import tpcl15 from '../../assets/img/res/two-piece/pants/casual/long/015.png'
import tpcl16 from '../../assets/img/res/two-piece/pants/casual/long/016.png'
import tpcl17 from '../../assets/img/res/two-piece/pants/casual/long/017.png'
import tpcl18 from '../../assets/img/res/two-piece/pants/casual/long/018.png'
import tpcl19 from '../../assets/img/res/two-piece/pants/casual/long/019.png'
import tpcl20 from '../../assets/img/res/two-piece/pants/casual/long/020.png'
import tpcl21 from '../../assets/img/res/two-piece/pants/casual/long/021.png'
import tpcl22 from '../../assets/img/res/two-piece/pants/casual/long/022.png'
import tpcl23 from '../../assets/img/res/two-piece/pants/casual/long/023.png'
import tpcl24 from '../../assets/img/res/two-piece/pants/casual/long/024.png'
import tpcl25 from '../../assets/img/res/two-piece/pants/casual/long/025.png'
import tpcl26 from '../../assets/img/res/two-piece/pants/casual/long/026.png'
import tpcl27 from '../../assets/img/res/two-piece/pants/casual/long/027.png'
import tpcl28 from '../../assets/img/res/two-piece/pants/casual/long/028.png'
import tpcl29 from '../../assets/img/res/two-piece/pants/casual/long/029.png'
import tpcl30 from '../../assets/img/res/two-piece/pants/casual/long/030.png'
import tpcl31 from '../../assets/img/res/two-piece/pants/casual/long/031.png'
import tpcl32 from '../../assets/img/res/two-piece/pants/casual/long/032.png'
import tpcl33 from '../../assets/img/res/two-piece/pants/casual/long/033.png'
import tpcl34 from '../../assets/img/res/two-piece/pants/casual/long/034.png'
import tpcl35 from '../../assets/img/res/two-piece/pants/casual/long/035.png'

// two-piece pants casual short
import tpcs01 from '../../assets/img/res/two-piece/pants/casual/short/001.png'
import tpcs02 from '../../assets/img/res/two-piece/pants/casual/short/002.png'
import tpcs03 from '../../assets/img/res/two-piece/pants/casual/short/003.png'
import tpcs04 from '../../assets/img/res/two-piece/pants/casual/short/004.png'
import tpcs05 from '../../assets/img/res/two-piece/pants/casual/short/005.png'
import tpcs06 from '../../assets/img/res/two-piece/pants/casual/short/006.png'
import tpcs07 from '../../assets/img/res/two-piece/pants/casual/short/007.png'
import tpcs08 from '../../assets/img/res/two-piece/pants/casual/short/008.png'
import tpcs09 from '../../assets/img/res/two-piece/pants/casual/short/009.png'
import tpcs10 from '../../assets/img/res/two-piece/pants/casual/short/010.png'
import tpcs11 from '../../assets/img/res/two-piece/pants/casual/short/011.png'
import tpcs12 from '../../assets/img/res/two-piece/pants/casual/short/012.png'
import tpcs13 from '../../assets/img/res/two-piece/pants/casual/short/013.png'
import tpcs14 from '../../assets/img/res/two-piece/pants/casual/short/014.png'
import tpcs15 from '../../assets/img/res/two-piece/pants/casual/short/015.png'
import tpcs16 from '../../assets/img/res/two-piece/pants/casual/short/016.png'
import tpcs17 from '../../assets/img/res/two-piece/pants/casual/short/017.png'
import tpcs18 from '../../assets/img/res/two-piece/pants/casual/short/018.png'
import tpcs19 from '../../assets/img/res/two-piece/pants/casual/short/019.png'
import tpcs20 from '../../assets/img/res/two-piece/pants/casual/short/020.png'
import tpcs21 from '../../assets/img/res/two-piece/pants/casual/short/021.png'
import tpcs22 from '../../assets/img/res/two-piece/pants/casual/short/022.png'
import tpcs23 from '../../assets/img/res/two-piece/pants/casual/short/023.png'
import tpcs24 from '../../assets/img/res/two-piece/pants/casual/short/024.png'
import tpcs25 from '../../assets/img/res/two-piece/pants/casual/short/025.png'
import tpcs26 from '../../assets/img/res/two-piece/pants/casual/short/026.png'
import tpcs27 from '../../assets/img/res/two-piece/pants/casual/short/027.png'
import tpcs28 from '../../assets/img/res/two-piece/pants/casual/short/028.png'
import tpcs29 from '../../assets/img/res/two-piece/pants/casual/short/029.png'
import tpcs30 from '../../assets/img/res/two-piece/pants/casual/short/030.png'
import tpcs31 from '../../assets/img/res/two-piece/pants/casual/short/031.png'

// two-piece pants dress long
import tpdl01 from '../../assets/img/res/two-piece/pants/dress/long/001.png'
import tpdl02 from '../../assets/img/res/two-piece/pants/dress/long/002.png'
import tpdl03 from '../../assets/img/res/two-piece/pants/dress/long/003.png'
import tpdl04 from '../../assets/img/res/two-piece/pants/dress/long/004.png'
import tpdl05 from '../../assets/img/res/two-piece/pants/dress/long/005.png'
import tpdl06 from '../../assets/img/res/two-piece/pants/dress/long/006.png'
import tpdl07 from '../../assets/img/res/two-piece/pants/dress/long/007.png'
import tpdl08 from '../../assets/img/res/two-piece/pants/dress/long/008.png'
import tpdl09 from '../../assets/img/res/two-piece/pants/dress/long/009.png'
import tpdl10 from '../../assets/img/res/two-piece/pants/dress/long/010.png'
import tpdl11 from '../../assets/img/res/two-piece/pants/dress/long/011.png'

// two-piece pants dress short
import tpds01 from '../../assets/img/res/two-piece/pants/dress/short/001.png'

// two-piece skirt casual long
import tscl01 from '../../assets/img/res/two-piece/skirt/casual/long/001.png'
import tscl02 from '../../assets/img/res/two-piece/skirt/casual/long/002.png'
import tscl03 from '../../assets/img/res/two-piece/skirt/casual/long/003.png'
import tscl04 from '../../assets/img/res/two-piece/skirt/casual/long/004.png'
import tscl05 from '../../assets/img/res/two-piece/skirt/casual/long/005.png'
import tscl06 from '../../assets/img/res/two-piece/skirt/casual/long/006.png'

// two-piece skirt casual short
import tscs01 from '../../assets/img/res/two-piece/skirt/casual/short/001.png'
import tscs02 from '../../assets/img/res/two-piece/skirt/casual/short/002.png'
import tscs03 from '../../assets/img/res/two-piece/skirt/casual/short/003.png'
import tscs04 from '../../assets/img/res/two-piece/skirt/casual/short/004.png'
import tscs05 from '../../assets/img/res/two-piece/skirt/casual/short/005.png'
import tscs06 from '../../assets/img/res/two-piece/skirt/casual/short/006.png'
import tscs07 from '../../assets/img/res/two-piece/skirt/casual/short/007.png'
import tscs08 from '../../assets/img/res/two-piece/skirt/casual/short/008.png'
import tscs09 from '../../assets/img/res/two-piece/skirt/casual/short/009.png'
import tscs10 from '../../assets/img/res/two-piece/skirt/casual/short/010.png'
import tscs11 from '../../assets/img/res/two-piece/skirt/casual/short/011.png'
import tscs12 from '../../assets/img/res/two-piece/skirt/casual/short/012.png'
import tscs13 from '../../assets/img/res/two-piece/skirt/casual/short/013.png'
import tscs14 from '../../assets/img/res/two-piece/skirt/casual/short/014.png'
import tscs15 from '../../assets/img/res/two-piece/skirt/casual/short/015.png'
import tscs16 from '../../assets/img/res/two-piece/skirt/casual/short/016.png'
import tscs17 from '../../assets/img/res/two-piece/skirt/casual/short/017.png'
import tscs18 from '../../assets/img/res/two-piece/skirt/casual/short/018.png'
import tscs19 from '../../assets/img/res/two-piece/skirt/casual/short/019.png'
import tscs20 from '../../assets/img/res/two-piece/skirt/casual/short/020.png'
import tscs21 from '../../assets/img/res/two-piece/skirt/casual/short/021.png'
import tscs22 from '../../assets/img/res/two-piece/skirt/casual/short/022.png'
import tscs23 from '../../assets/img/res/two-piece/skirt/casual/short/023.png'
import tscs24 from '../../assets/img/res/two-piece/skirt/casual/short/024.png'
import tscs25 from '../../assets/img/res/two-piece/skirt/casual/short/025.png'
import tscs26 from '../../assets/img/res/two-piece/skirt/casual/short/026.png'
import tscs27 from '../../assets/img/res/two-piece/skirt/casual/short/027.png'
import tscs28 from '../../assets/img/res/two-piece/skirt/casual/short/028.png'
import tscs29 from '../../assets/img/res/two-piece/skirt/casual/short/029.png'
import tscs30 from '../../assets/img/res/two-piece/skirt/casual/short/030.png'
import tscs31 from '../../assets/img/res/two-piece/skirt/casual/short/031.png'
import tscs32 from '../../assets/img/res/two-piece/skirt/casual/short/032.png'
import tscs33 from '../../assets/img/res/two-piece/skirt/casual/short/033.png'
import tscs34 from '../../assets/img/res/two-piece/skirt/casual/short/034.png'
import tscs35 from '../../assets/img/res/two-piece/skirt/casual/short/035.png'
import tscs36 from '../../assets/img/res/two-piece/skirt/casual/short/036.png'
import tscs37 from '../../assets/img/res/two-piece/skirt/casual/short/037.png'
import tscs38 from '../../assets/img/res/two-piece/skirt/casual/short/038.png'

// two-piece skirt dress long
import tsdl01 from '../../assets/img/res/two-piece/skirt/dress/long/001.png'
import tsdl02 from '../../assets/img/res/two-piece/skirt/dress/long/002.png'
import tsdl03 from '../../assets/img/res/two-piece/skirt/dress/long/003.png'
import tsdl04 from '../../assets/img/res/two-piece/skirt/dress/long/004.png'
import tsdl05 from '../../assets/img/res/two-piece/skirt/dress/long/005.png'
import tsdl06 from '../../assets/img/res/two-piece/skirt/dress/long/006.png'
import tsdl07 from '../../assets/img/res/two-piece/skirt/dress/long/007.png'
import tsdl08 from '../../assets/img/res/two-piece/skirt/dress/long/008.png'
import tsdl09 from '../../assets/img/res/two-piece/skirt/dress/long/009.png'
import tsdl10 from '../../assets/img/res/two-piece/skirt/dress/long/010.png'
import tsdl11 from '../../assets/img/res/two-piece/skirt/dress/long/011.png'
import tsdl12 from '../../assets/img/res/two-piece/skirt/dress/long/012.png'
import tsdl13 from '../../assets/img/res/two-piece/skirt/dress/long/013.png'
import tsdl14 from '../../assets/img/res/two-piece/skirt/dress/long/014.png'

// two-piece skirt dress short
import tsds01 from '../../assets/img/res/two-piece/skirt/dress/short/001.png'
import tsds02 from '../../assets/img/res/two-piece/skirt/dress/short/002.png'
import tsds03 from '../../assets/img/res/two-piece/skirt/dress/short/003.png'
import tsds04 from '../../assets/img/res/two-piece/skirt/dress/short/004.png'
import tsds05 from '../../assets/img/res/two-piece/skirt/dress/short/005.png'
import tsds06 from '../../assets/img/res/two-piece/skirt/dress/short/006.png'
import tsds07 from '../../assets/img/res/two-piece/skirt/dress/short/007.png'
import tsds08 from '../../assets/img/res/two-piece/skirt/dress/short/008.png'
import tsds09 from '../../assets/img/res/two-piece/skirt/dress/short/009.png'
import tsds10 from '../../assets/img/res/two-piece/skirt/dress/short/010.png'
import tsds11 from '../../assets/img/res/two-piece/skirt/dress/short/011.png'
import tsds12 from '../../assets/img/res/two-piece/skirt/dress/short/012.png'
import tsds13 from '../../assets/img/res/two-piece/skirt/dress/short/013.png'

// one-piece long black mesh arrays
const olbmArrays = [olbm01, olbm02, olbm03]

// one-piece long black off shoulder arrays
const olboArrays = [
	olbo01,
	olbo02,
	olbo03,
	olbo04,
	olbo05,
	olbo06,
	olbo07,
	olbo08,
	olbo09,
	olbo10,
	olbo11,
	olbo12,
	olbo13,
]

// one-piece long white mesh arrays
const olwmArrays = [olwm01]

// one-piece long white off shoulder arrays
const olwoArrays = [
	olwo01,
	olwo02,
	olwo03,
	olwo04,
	olwo05,
	olwo06,
	olwo07,
	olwo08,
	olwo09,
	olwo10,
	olwo11,
	olwo12,
	olwo13,
	olwo14,
]

// one-piece short black mesh arrays
const osbmArrays = [
	osbm01,
	osbm02,
	osbm03,
	osbm04,
	osbm05,
	osbm06,
	osbm07,
	osbm08,
]

// one-piece short black off shoulder arrays
const osboArrays = [
	osbo01,
	osbo02,
	osbo03,
	osbo04,
	osbo05,
	osbo06,
	osbo07,
	osbo08,
	osbo09,
	osbo10,
	osbo11,
]

// one-piece short white mesh arrays
const oswmArrays = [
	oswm01,
	oswm02,
	oswm03,
	oswm04,
	oswm05,
	oswm06,
	oswm07,
	oswm08,
	oswm09,
	oswm10,
]

// one-piece short white off shoulder arrays
const oswoArrays = [
	oswo01,
	oswo02,
	oswo03,
	oswo04,
	oswo05,
	oswo06,
	oswo07,
	oswo08,
	oswo09,
	oswo10,
]

// two-piece pants casual long arrays
const tpclArrays = [
	tpcl01,
	tpcl02,
	tpcl03,
	tpcl04,
	tpcl05,
	tpcl06,
	tpcl07,
	tpcl08,
	tpcl09,
	tpcl10,
	tpcl11,
	tpcl12,
	tpcl13,
	tpcl14,
	tpcl15,
	tpcl16,
	tpcl17,
	tpcl18,
	tpcl19,
	tpcl20,
	tpcl21,
	tpcl22,
	tpcl23,
	tpcl24,
	tpcl25,
	tpcl26,
	tpcl27,
	tpcl28,
	tpcl29,
	tpcl30,
	tpcl31,
	tpcl32,
	tpcl33,
	tpcl34,
	tpcl35,
]

// two-piece pants casual short arrays
const tpcsArrays = [
	tpcs01,
	tpcs02,
	tpcs03,
	tpcs04,
	tpcs05,
	tpcs06,
	tpcs07,
	tpcs08,
	tpcs09,
	tpcs10,
	tpcs11,
	tpcs12,
	tpcs13,
	tpcs14,
	tpcs15,
	tpcs16,
	tpcs17,
	tpcs18,
	tpcs19,
	tpcs20,
	tpcs21,
	tpcs22,
	tpcs23,
	tpcs24,
	tpcs25,
	tpcs26,
	tpcs27,
	tpcs28,
	tpcs29,
	tpcs30,
	tpcs31,
]

// two-piece pants dress long arrays
const tpdlArrays = [
	tpdl01,
	tpdl02,
	tpdl03,
	tpdl04,
	tpdl05,
	tpdl06,
	tpdl07,
	tpdl08,
	tpdl09,
	tpdl10,
	tpdl11,
]

// two-piece pants dress short arrays
const tpdsArrays = [tpds01]

// two-piece skirt casual long arrays
const tsclArrays = [tscl01, tscl02, tscl03, tscl04, tscl05, tscl06]

// two-piece skirt casual short arrays
const tscsArrays = [
	tscs01,
	tscs02,
	tscs03,
	tscs04,
	tscs05,
	tscs06,
	tscs07,
	tscs08,
	tscs09,
	tscs10,
	tscs11,
	tscs12,
	tscs13,
	tscs14,
	tscs15,
	tscs16,
	tscs17,
	tscs18,
	tscs19,
	tscs20,
	tscs21,
	tscs22,
	tscs23,
	tscs24,
	tscs25,
	tscs26,
	tscs27,
	tscs28,
	tscs29,
	tscs30,
	tscs31,
	tscs32,
	tscs33,
	tscs34,
	tscs35,
	tscs36,
	tscs37,
	tscs38,
]

// two-piece skirt dress long arrays
const tsdlArrays = [
	tsdl01,
	tsdl02,
	tsdl03,
	tsdl04,
	tsdl05,
	tsdl06,
	tsdl07,
	tsdl08,
	tsdl09,
	tsdl10,
	tsdl11,
	tsdl12,
	tsdl13,
	tsdl14,
]

// two-piece skirt dress short arrays
const tsdsArrays = [
	tsds01,
	tsds02,
	tsds03,
	tsds04,
	tsds05,
	tsds06,
	tsds07,
	tsds08,
	tsds09,
	tsds10,
	tsds11,
	tsds12,
	tsds13,
]

const TabItem = styled.li`
	button {
		background: #777777;
		color: #fff;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		width: 200px;
		height: 30px;
		transition: all 0.3s ease;
		&.active {
			font-weight: bold;
			background: var(--color-primary);
		}
		&:hover,
		&:focus,
		&:active {
			background: var(--color-primary);
		}
	}
`
const TabContent = styled.div`
	.tabContentItem {
		position: relative;
		border: 3px solid var(--color-primary);
	}
`
const FileItem = styled.li`
	transform: translateY(-10px);
	button {
		background: #8c73f4;
		color: #fff;
		border-radius: 5px;
		width: 200px;
		height: 30px;
	}
`
const ModelControlContainer = styled.div`
	background: #efefef;
	width: 370px;
	min-height: 550px;
`
const ModelBottomContainer = styled.div`
	button {
		background: var(--color-primary);
		color: #fff;
		height: 60px;
		border-radius: 5px;
		width: 200px;
	}
`
const SelectBox = styled.div`
	.label {
		background: var(--color-primary);
		color: #fff;
		border-radius: 50px;
	}
	.value {
		color: #fff;
		border-radius: 50px;
		background: #8c73f4;
		width: 50%;
	}
	.selectBoxRow {
		left: 50%;
		border-radius: 20px;
		background: #8c73f4;
		color: #fff;
		display: none;
		z-index: 2;
		&.active {
			display: grid;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		}
		button {
			background: #b4a9f7;
			color: #fff;
			border-radius: 20px;
		}
	}
	.select {
		border-radius: 20px;
		background: #8c73f4;
		color: #fff;
		overflow: hidden;
		display: none;
		z-index: 2;
		&.active {
			display: grid;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		}
		li {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		button {
			background: #b4a9f7;
			color: #fff;
			border-radius: 20px;
		}
	}
`
const ModelContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 400px;
	bottom: 0;
`
const ModelResultContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 400px;
	bottom: 0;
	min-height: 550px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		max-height: 550px;
	}
`

const VideoContainer = styled.div`
	.btn {
		background: var(--color-primary);
		color: #fff;
		border-radius: 5px;
		padding: 10px;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
`

const SelectResultButton = styled.button``

const LoadingAnim = styled.div`
	.loader {
		width: 40px;
		height: 20px;
		--c: no-repeat radial-gradient(farthest-side, #000 93%, #0000);
		background: var(--c) 0 0, var(--c) 50% 0;
		background-size: 8px 8px;
		position: relative;
		clip-path: inset(-200% -100% 0 0);
		animation: l6-0 1.5s linear infinite;
	}
	.loader:before {
		content: '';
		position: absolute;
		width: 8px;
		height: 12px;
		background: #000;
		left: -16px;
		top: 0;
		animation: l6-1 1.5s linear infinite,
			l6-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
	}
	.loader:after {
		content: '';
		position: absolute;
		inset: 0 0 auto auto;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #000;
		animation: l6-3 1.5s linear infinite;
	}
	@keyframes l6-0 {
		0%,
		30% {
			background-position: 0 0, 50% 0;
		}
		33% {
			background-position: 0 100%, 50% 0;
		}
		41%,
		63% {
			background-position: 0 0, 50% 0;
		}
		66% {
			background-position: 0 0, 50% 100%;
		}
		74%,
		100% {
			background-position: 0 0, 50% 0;
		}
	}
	@keyframes l6-1 {
		90% {
			transform: translateY(0);
		}
		95% {
			transform: translateY(15px);
		}
		100% {
			transform: translateY(15px);
			left: calc(100% - 8px);
		}
	}
	@keyframes l6-2 {
		100% {
			top: -0.1px;
		}
	}
	@keyframes l6-3 {
		0%,
		80%,
		100% {
			transform: translate(0);
		}
		90% {
			transform: translate(26px);
		}
	}
`

const patternArrays = [
	{
		id: 1,
		value: 'None',
	},
	{
		id: 2,
		value: '꽃무늬',
	},
	{
		id: 3,
		value: '물방울 무늬',
	},
	{
		id: 4,
		value: '민무늬',
	},
]
const colorArrays = [
	{
		id: 1,
		value: 'None',
	},
	{
		id: 2,
		value: '화이트',
	},
	{
		id: 3,
		value: '블랙',
	},
	{
		id: 4,
		value: '블루',
	},
]

const type1Arrays = [
	{
		id: 1,
		value: 'One-Piece',
	},
	{
		id: 2,
		value: 'Two-Piece',
	},
]

const DrawingPage = () => {
	const [tab, setTab] = useState(1)
	const [styleInfo, setStyleInfo] = useState({
		style: 'Casual',
		race: 'race 1',
		sex: 'Female',
		bodyType: 'type 1',
		face: 'type 1',
		faceSwap: 'type 1',
		hair: 'type 1',
		skin: 'type 1',
		pose: 'type 1',
		background: 'type 1',
	})
	const [clothInfo, setClothInfo] = useState({
		type1: 'One-Piece',
		type2: 'Short Dress',
		type3: 'Black',
		type4: 'Mesh',
	})
	// useState name  dressIndex is null or number
	const [dressIndex, setDressIndex] = useState(0)
	const [dressArrayType, setDressArrayType] = useState('osbm')
	const [isAnimate, setIsAnimate] = useState(false)

	const [type2List, setType2List] = useState([
		{
			id: 0,
			value: 'Short Dress',
		},
		{
			id: 1,
			value: 'Long Dress',
		},
	])
	const [type3List, setType3List] = useState([
		{
			id: 0,
			value: 'Black',
		},
		{
			id: 1,
			value: 'White',
		},
	])
	const [type4List, setType4List] = useState([
		{
			id: 0,
			value: 'Mesh',
		},
		{
			id: 1,
			value: 'Off Shoulder',
		},
	])

	// 모델 결과
	const [modelSex, setModelSex] = useState('Female')
	const [modelNumber, setModelNumber] = useState('I0079')

	// 임시 모델 정보
	const [tmpStyleInfo, setTmpStyleInfo] = useState({
		style: 'Casual',
		race: 'race 1',
		sex: 'Female',
		bodyType: 'type 1',
		face: 'type 1',
		faceSwap: 'type 1',
		hair: 'type 1',
		skin: 'type 1',
		pose: 'type 1',
		background: 'type 1',
	})
	const [tmpClothInfo, setTmpClothInfo] = useState({
		type1: 'None',
		type2: 'None',
		type3: 'None',
		type4: 'None',
	})

	// 임시 모델 결과
	const [tmpModelSex, setTmpModelSex] = useState('')
	const [tmpModelNumber, setTmpModelNumber] = useState(0)

	// 촬영 버튼 클릭 여부
	const [isShotPressed, setIsShotPressed] = useState(false)

	const [showModel, setShowModel] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handelShotButton = () => {
		// 촬영 버튼을 누르면 tmpStyleInfo와 tmpClothInfo를 styleInfo와 clothInfo로 변경한다.
		if (tmpStyleInfo.sex === 'Female') {
			// setStyleInfo(tmpStyleInfo)
			// setClothInfo(tmpClothInfo)
			imageGenerator()
			setIsShotPressed(true)
			setIsLoading(true)
			setTimeout(() => {
				setIsLoading(false)
				setShowModel(true)
			}, 3000)
		}
	}

	const onStyleClick = (id: string) => {
		// .select 클래스를 가진 요소들을 찾아서 active 클래스를 제거하되 현재 클릭한 요소는 .active 클래스가 있을 경우 .active를 제거하고 없을 경우 .active를 추가한다.
		document.querySelectorAll('.select').forEach((item) => {
			const itemID = item.id
			if (itemID === id) {
				item.classList.toggle('active')
			}
			if (itemID !== id) {
				item.classList.remove('active')
			}
		})
	}
	const handleStyleChange = (target: string, style: string, id: string) => {
		// 시연용 셋팅
		if (target === 'sex' && style === 'Female') {
			setTmpStyleInfo({
				sex: 'Female',
				race: 'White',
				style: 'Casual',
				bodyType: 'Slender',
				face: 'Default',
				faceSwap: 'None',
				hair: 'Long',
				skin: 'Normal',
				pose: 'Stand',
				background: 'White',
			})
			// setTmpClothInfo({
			// 	type1: 'None',
			// 	type2: 'None',
			// 	type3: 'None',
			// 	type4: 'None',
			// })
			setModelSex('Female')
			setModelNumber('')
		} else if (target === 'sex' && style === 'Male') {
			setTmpStyleInfo({
				sex: 'Male',
				race: 'Yellow',
				style: 'Casual',
				bodyType: 'Muscle',
				face: 'Default',
				faceSwap: 'None',
				hair: 'Short',
				skin: 'Normal',
				pose: 'Stand',
				background: 'White',
			})
			// setTmpClothInfo({
			// 	type1: 'None',
			// 	type2: 'None',
			// 	type3: 'None',
			// 	type4: 'None',
			// })
			setModelSex('Male')
			setModelNumber('')
		}
		setShowModel(false)
		// close select box
		const styleSelect = document.getElementById(id)
		if (styleSelect) {
			styleSelect.classList.remove('active')
		}
	}

	const onClothClick = (id: string) => {
		// .select 클래스를 가진 요소들을 찾아서 active 클래스를 제거하되 현재 클릭한 요소는 .active 클래스가 있을 경우 .active를 제거하고 없을 경우 .active를 추가한다.
		document.querySelectorAll('.select').forEach((item) => {
			const itemID = item.id
			if (itemID === id) {
				item.classList.toggle('active')
			}
			if (itemID !== id) {
				item.classList.remove('active')
			}
		})
	}

	const [femaleResult, setFemaleResult] = useState({
		isBlouse: false,
		isLongSkirt: false,
		isHighHeels: false,
	})
	const [maleResult, setMaleResult] = useState({
		isCap: false,
		isShirts: false,
		isJeans: false,
	})

	const handleColthChange = (target: string, style: string, id: string) => {}

	const handleSubSelect = (target: string, id: string) => {
		// .select 클래스를 가진 요소들을 찾아서 active 클래스를 제거하되 현재 클릭한 요소는 .active 클래스가 있을 경우 .active를 제거하고 없을 경우 .active를 추가한다.
		document.querySelectorAll('.select').forEach((item) => {
			const itemID = item.id
			if (itemID === id) {
				item.classList.toggle('active')
			}
			if (itemID !== id) {
				item.classList.remove('active')
			}
		})
	}

	const handleSubSelectChange = (key: string, value: string) => {
		setClothInfo({
			...clothInfo,
			[key]: value,
		})

		if (key === 'type1') {
			document.querySelectorAll('.selectBoxRow').forEach((item) => {
				item.classList.remove('active')
			})
		}

		// remove .active from all .select
		document.querySelectorAll('.select').forEach((item) => {
			item.classList.remove('active')
		})
		console.log(key, value)
		if (key === 'type1' && value === 'One-Piece') {
			setType2List([
				{
					id: 1,
					value: 'Short Dress',
				},
				{
					id: 2,
					value: 'Long Dress',
				},
			])
			setType3List([
				{
					id: 1,
					value: 'Black',
				},
				{
					id: 2,
					value: 'White',
				},
			])
			setType4List([
				{
					id: 1,
					value: 'Mesh',
				},
				{
					id: 2,
					value: 'Off Shoulder',
				},
			])
			setClothInfo({
				type1: 'One-Piece',
				type2: 'Short Dress',
				type3: 'Black',
				type4: 'Mesh',
			})
		} else if (key === 'type1' && value === 'Two-Piece') {
			setType2List([
				{
					id: 1,
					value: 'Skirt',
				},
				{
					id: 2,
					value: 'Pants',
				},
			])
			setType3List([
				{
					id: 1,
					value: 'Casual',
				},
				{
					id: 2,
					value: 'Dress Up',
				},
			])
			setType4List([
				{
					id: 1,
					value: 'Long',
				},
				{
					id: 2,
					value: 'Short',
				},
			])
			setClothInfo({
				type1: 'Two-Piece',
				type2: 'Skirt',
				type3: 'Casual',
				type4: 'Short',
			})
		} else if (key === 'type1' && value === 'None') {
			setType2List([
				{
					id: 1,
					value: '',
				},
			])
			setType3List([
				{
					id: 1,
					value: '',
				},
			])
			setType4List([
				{
					id: 1,
					value: '',
				},
			])
		}
		console.log(clothInfo.type1, value)
		if (
			(clothInfo.type1 === 'One-Piece' && value === 'Short Dress') ||
			(clothInfo.type1 === 'One-Piece' && value === 'Long Dress')
		) {
			setType3List([
				{
					id: 1,
					value: 'None',
				},
				{
					id: 2,
					value: 'Black',
				},
				{
					id: 3,
					value: 'White',
				},
			])
			setType4List([
				{
					id: 1,
					value: 'Mesh',
				},
				{
					id: 2,
					value: 'Off Shoulder',
				},
			])
		} else if (
			(clothInfo.type1 === 'Two-Piece' && value === 'Skirt') ||
			(clothInfo.type1 === 'Two-Piece' && value === 'Pants')
		) {
			setType3List([
				{
					id: 1,
					value: 'Casual',
				},
				{
					id: 2,
					value: 'Dress Up',
				},
			])
			setType4List([
				{
					id: 1,
					value: 'Short',
				},
				{
					id: 2,
					value: 'Long',
				},
			])
		} else if (key === 'type2' && value === 'None') {
			setType3List([
				{
					id: 1,
					value: '',
				},
			])
			setType4List([
				{
					id: 1,
					value: '',
				},
			])
		}
	}

	const imageGenerator = () => {
		console.log('gen')
		console.table(clothInfo)
		if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Short Dress' &&
			clothInfo.type3 === 'Black' &&
			clothInfo.type4 === 'Mesh'
		) {
			console.log('osbm')
			setModelNumber('I0079')
			// get random index of osbmArrays
			const randomIndex = Math.floor(Math.random() * osbmArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('osbm')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Short Dress' &&
			clothInfo.type3 === 'Black' &&
			clothInfo.type4 === 'Off Shoulder'
		) {
			console.log('osbo')
			setModelNumber('I0074')
			// get random index of osboArrays
			const randomIndex = Math.floor(Math.random() * osboArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('osbo')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Short Dress' &&
			clothInfo.type3 === 'White' &&
			clothInfo.type4 === 'Mesh'
		) {
			console.log('oswm')
			setModelNumber('I0130')
			// get random index of oswmArrays
			const randomIndex = Math.floor(Math.random() * oswmArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('oswm')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Short Dress' &&
			clothInfo.type3 === 'White' &&
			clothInfo.type4 === 'Off Shoulder'
		) {
			console.log('oswo')
			setModelNumber('I0119')
			// get random index of oswoArrays
			const randomIndex = Math.floor(Math.random() * oswoArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('oswo')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Long Dress' &&
			clothInfo.type3 === 'Black' &&
			clothInfo.type4 === 'Mesh'
		) {
			console.log('olbm')
			setModelNumber('I0080')
			// get random index of olbmArrays
			const randomIndex = Math.floor(Math.random() * olbmArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('olbm')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Long Dress' &&
			clothInfo.type3 === 'Black' &&
			clothInfo.type4 === 'Off Shoulder'
		) {
			console.log('olbo')
			setModelNumber('I0076')
			// get random index of olboArrays
			const randomIndex = Math.floor(Math.random() * olboArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('olbo')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Long Dress' &&
			clothInfo.type3 === 'White' &&
			clothInfo.type4 === 'Mesh'
		) {
			console.log('olwm')
			setModelNumber('I0085')
			// get random index of olwmArrays
			const randomIndex = Math.floor(Math.random() * olwmArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('olwm')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'One-Piece' &&
			clothInfo.type2 === 'Long Dress' &&
			clothInfo.type3 === 'White' &&
			clothInfo.type4 === 'Off Shoulder'
		) {
			console.log('olwo')
			setModelNumber('I0105')
			// get random index of olwoArrays
			const randomIndex = Math.floor(Math.random() * olwoArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('olwo')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Skirt' &&
			clothInfo.type3 === 'Casual' &&
			clothInfo.type4 === 'Short'
		) {
			console.log('tscs')
			setModelNumber('I0004')
			// get random index of tscsArrays
			const randomIndex = Math.floor(Math.random() * tscsArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tscs')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Skirt' &&
			clothInfo.type3 === 'Casual' &&
			clothInfo.type4 === 'Long'
		) {
			console.log('tscl')
			setModelNumber('I0094')
			// get random index of tsclArrays
			const randomIndex = Math.floor(Math.random() * tsclArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tscl')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Skirt' &&
			clothInfo.type3 === 'Dress Up' &&
			clothInfo.type4 === 'Short'
		) {
			console.log('tsds')
			setModelNumber('I0089')
			// get random index of tsdsArrays
			const randomIndex = Math.floor(Math.random() * tsdsArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tsds')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Skirt' &&
			clothInfo.type3 === 'Dress Up' &&
			clothInfo.type4 === 'Long'
		) {
			console.log('tsdl')
			setModelNumber('I0114')
			// get random index of tsdlArrays
			const randomIndex = Math.floor(Math.random() * tsdlArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tsdl')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Pants' &&
			clothInfo.type3 === 'Casual' &&
			clothInfo.type4 === 'Short'
		) {
			console.log('tpcs')
			setModelNumber('I0002')
			// get random index of tpcsArrays
			const randomIndex = Math.floor(Math.random() * tpcsArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tpcs')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Pants' &&
			clothInfo.type3 === 'Casual' &&
			clothInfo.type4 === 'Long'
		) {
			console.log('tpcl')
			setModelNumber('I0003')
			// get random index of tpclArrays
			const randomIndex = Math.floor(Math.random() * tpclArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tpcl')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Pants' &&
			clothInfo.type3 === 'Dress Up' &&
			clothInfo.type4 === 'Short'
		) {
			console.log('tpds')
			setModelNumber('I0120')
			// get random index of tpdsArrays
			const randomIndex = Math.floor(Math.random() * tpdsArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tpds')
			setShowModel(false)
		} else if (
			clothInfo.type1 === 'Two-Piece' &&
			clothInfo.type2 === 'Pants' &&
			clothInfo.type3 === 'Dress Up' &&
			clothInfo.type4 === 'Long'
		) {
			console.log('tpdl')
			setModelNumber('I0140')
			// get random index of tpdlArrays
			const randomIndex = Math.floor(Math.random() * tpdlArrays.length)
			setDressIndex(randomIndex)
			setDressArrayType('tpdl')
			setShowModel(false)
		} else {
			console.log('else')
		}
	}

	useEffect(() => {
		imageGenerator()
	}, [clothInfo, dressArrayType, dressIndex])

	// useEffect(() => {
	// 	// 여성 결과 확인
	// 	if (
	// 		femaleResult.isBlouse &&
	// 		femaleResult.isLongSkirt &&
	// 		femaleResult.isHighHeels
	// 	) {
	// 		setModelNumber(2)
	// 	} else {
	// 		setModelNumber(1)
	// 	}
	// }, [femaleResult])

	// useEffect(() => {
	// 	// 남성 결과 확인
	// 	if (maleResult.isCap && maleResult.isShirts && maleResult.isJeans) {
	// 		setModelNumber(2)
	// 	} else {
	// 		setModelNumber(1)
	// 	}
	// }, [maleResult])

	return (
		<div className="container mx-auto">
			<PageTitleBar className="">
				<div className="py-9 pl-20 flex items-center">
					<Link to="/">
						<img
							src={BtnBack}
							alt="back"
						/>
					</Link>
					<h2 className="text-xl color-primary pl-6">
						<b>Drawing note</b>
					</h2>
				</div>
			</PageTitleBar>
			<ol className="flex items-center justify-between mt-10">
				<div className="flex items-center gap-2">
					<TabItem>
						<button
							type="button"
							className={`text-xs text-cernter block p-2 ${tab === 1 ? 'active' : ''}`}
							onClick={() => setTab(1)}
						>
							CLOTH SETTING
						</button>
					</TabItem>
					<TabItem>
						<button
							type="button"
							className={`text-xs text-cernter block p-2 ${tab === 0 ? 'active' : ''}`}
							onClick={() => {
								alertModal('준비중입니다.', 'info', () => {})
							}}
						>
							MODEL SETTING
						</button>
					</TabItem>
				</div>
				<ul className="flex items-center gap-2">
					<FileItem>
						<button
							type="button"
							className="text-xs text-center block p-2"
							onClick={() => {
								alertModal('준비중입니다.', 'info', () => {})
							}}
						>
							All image
						</button>
					</FileItem>
					<FileItem>
						<button
							type="button"
							className="text-xs text-center block p-2"
							onClick={() => {
								alertModal('준비중입니다.', 'info', () => {})
							}}
						>
							Save & Load
						</button>
					</FileItem>
				</ul>
			</ol>
			<TabContent className="relative">
				{tab === 0 && (
					<>
						<div className="tabContentItem">
							<ModelControlContainer className="p-14">
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">STYLE</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('styleSelect')
										}}
									>
										{tmpStyleInfo.style}
									</button>
									<ul
										id="styleSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('style', 'Casual', 'styleSelect')
												}}
											>
												Casual
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('style', 'item 1', 'styleSelect')
												}}
											>
												item 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('style', 'item 2', 'styleSelect')
												}}
											>
												item 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">RACE</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('raceSelect')
										}}
									>
										{tmpStyleInfo.race}
									</button>
									<ul
										id="raceSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('race', 'race 1', 'raceSelect')
												}}
											>
												Casual
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('race', 'race 2', 'raceSelect')
												}}
											>
												item 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('race', 'race 3', 'raceSelect')
												}}
											>
												item 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">SEX</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('sexSelect')
										}}
									>
										{tmpStyleInfo.sex}
									</button>
									<ul
										id="sexSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('sex', 'Female', 'sexSelect')
												}}
											>
												Female
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('sex', 'Male', 'sexSelect')
												}}
											>
												Male
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">BODY TYPE</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('bodyTypeSelect')
										}}
									>
										{tmpStyleInfo.bodyType}
									</button>
									<ul
										id="bodyTypeSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('bodyType', 'type 1', 'bodyTypeSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('bodyType', 'type 2', 'bodyTypeSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">FACE</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('faceSelect')
										}}
									>
										{tmpStyleInfo.face}
									</button>
									<ul
										id="faceSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('face', 'type 1', 'faceSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('face', 'type 2', 'faceSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">FACE SWAP</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('faceSwapSelect')
										}}
									>
										{tmpStyleInfo.faceSwap}
									</button>
									<ul
										id="faceSwapSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('faceSwap', 'type 1', 'faceSwapSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('faceSwap', 'type 2', 'faceSwapSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">HAIR</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('hairSelect')
										}}
									>
										{tmpStyleInfo.hair}
									</button>
									<ul
										id="hairSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('hair', 'type 1', 'hairSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('hair', 'type 2', 'hairSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">SKIN</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('skinSelect')
										}}
									>
										{tmpStyleInfo.skin}
									</button>
									<ul
										id="skinSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('skin', 'type 1', 'skinSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('skin', 'type 2', 'skinSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">POSE</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('poseSelect')
										}}
									>
										{tmpStyleInfo.pose}
									</button>
									<ul
										id="poseSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('pose', 'type 1', 'poseSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('pose', 'type 2', 'poseSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
								<SelectBox className="relative">
									<p className="label px-4 py-3 text-sm">BACKGROUND</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onStyleClick('backgroundSelect')
										}}
									>
										{tmpStyleInfo.background}
									</button>
									<ul
										id="backgroundSelect"
										className={`select p-2 absolute right-0 text-center`}
									>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('background', 'type 1', 'backgroundSelect')
												}}
											>
												type 1
											</button>
										</li>
										<li>
											<button
												type="button"
												className="text-center p-3 block w-full"
												onClick={() => {
													handleStyleChange('background', 'type 2', 'backgroundSelect')
												}}
											>
												type 2
											</button>
										</li>
									</ul>
								</SelectBox>
							</ModelControlContainer>
							<ModelContainer>
								{isAnimate ? (
									<>
										<VideoContainer className="grid grid-cols-3 gap-4">
											<div>이미지</div>
											<div>
												<div>영상</div>
												<div>영상</div>
												<div>
													<button
														type="button"
														className="btn block w-full text-center"
													>
														영상 만들기
													</button>
													<button
														type="button"
														className="btn block w-full text-center"
													>
														영상 내보내기
													</button>
												</div>
											</div>
											<div>이미지</div>
										</VideoContainer>
									</>
								) : (
									<></>
								)}
							</ModelContainer>
							<ModelResultContainer>
								{isLoading && (
									<LoadingAnim>
										<div className="loader"></div>
									</LoadingAnim>
								)}
								{isShotPressed && showModel && (
									<img
										src={
											modelNumber === 'I0002'
												? I0002
												: modelNumber === 'I0003'
												? I0003
												: modelNumber === 'I0120'
												? I0120
												: modelNumber === 'I0140'
												? I0140
												: modelNumber === 'I0004'
												? I0004
												: modelNumber === 'I0094'
												? I0094
												: modelNumber === 'I0089'
												? I0089
												: modelNumber === 'I0114'
												? I0114
												: modelNumber === 'I0079'
												? I0079
												: modelNumber === 'I0074'
												? I0074
												: modelNumber === 'I0130'
												? I0130
												: modelNumber === 'I0119'
												? I0119
												: modelNumber === 'I0080'
												? I0080
												: modelNumber === 'I0076'
												? I0076
												: modelNumber === 'I0085'
												? I0085
												: modelNumber === 'I0105'
												? I0105
												: ''
										}
										alt=""
									/>
								)}
							</ModelResultContainer>
						</div>
					</>
				)}
				{tab === 1 && (
					<>
						<div className="tabContentItem">
							<ModelControlContainer className="p-14">
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">Type 1</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onClothClick('type1Select')
										}}
									>
										{clothInfo.type1}
									</button>
									<ul
										id="type1Select"
										className={`select absolute right-0 text-center p-2 w-40`}
									>
										{type1Arrays.map((item: any, index: number) => {
											return (
												<li key={item.id}>
													<button
														type="button"
														className="text-center p-3 block w-full"
														onClick={() => {
															handleSubSelectChange('type1', item.value)
														}}
													>
														{item.value}
													</button>
												</li>
											)
										})}
									</ul>
									<div
										id="t11ype1Select"
										className={`absolute right-0 text-center selectBoxRow grid-cols-1 gap-2 w-40 h-16 p-2`}
									>
										<div className="relative">
											<SelectResultButton
												type="button"
												className="block w-full h-full"
												onClick={() => handleSubSelect('type1Item', 'type1ItemSelect')}
											>
												{clothInfo.type1}
											</SelectResultButton>
										</div>
									</div>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">Type 2</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onClothClick('type2Select')
										}}
									>
										{clothInfo.type2}
									</button>
									<ul
										id="type2Select"
										className={`select absolute right-0 text-center p-2 w-40`}
									>
										{type2List.map((item: any, index: number) => {
											return (
												<li key={item.id}>
													<button
														type="button"
														className="text-center p-3 block w-full"
														onClick={() => {
															handleSubSelectChange('type2', item.value)
														}}
													>
														{item.value}
													</button>
												</li>
											)
										})}
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">Type 3</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onClothClick('type3Select')
										}}
									>
										{clothInfo.type3}
									</button>
									<ul
										id="type3Select"
										className={`select absolute right-0 text-center p-2 w-40`}
									>
										{type3List.map((item: any, index: number) => {
											return (
												<li key={item.id}>
													<button
														type="button"
														className="text-center p-3 block w-full"
														onClick={() => {
															handleSubSelectChange('type3', item.value)
														}}
													>
														{item.value}
													</button>
												</li>
											)
										})}
									</ul>
								</SelectBox>
								<SelectBox className="relative mb-5">
									<p className="label px-4 py-3 text-sm">Type 4</p>
									<button
										type="button"
										className="value px-4 py-3 text-sm absolute top-0 right-0 text-center"
										onClick={() => {
											onClothClick('type4Select')
										}}
									>
										{clothInfo.type4}
									</button>
									<ul
										id="type4Select"
										className={`select absolute right-0 text-center p-2 w-40`}
									>
										{type4List.map((item: any, index: number) => {
											return (
												<li key={item.id}>
													<button
														type="button"
														className="text-center p-3 block w-full"
														onClick={() => {
															handleSubSelectChange('type4', item.value)
														}}
													>
														{item.value}
													</button>
												</li>
											)
										})}
									</ul>
								</SelectBox>
							</ModelControlContainer>
							<ModelContainer>
								{isAnimate ? (
									<>
										<VideoContainer className="grid grid-cols-3 gap-4">
											<div>이미지</div>
											<div>
												<div>영상</div>
												<div>영상</div>
												<div>
													<button
														type="button"
														className="btn block w-full text-center"
													>
														영상 만들기
													</button>
													<button
														type="button"
														className="btn block w-full text-center"
													>
														영상 내보내기
													</button>
												</div>
											</div>
											<div>이미지</div>
										</VideoContainer>
									</>
								) : (
									<></>
								)}
							</ModelContainer>
							<ModelResultContainer>
								{isLoading && (
									<LoadingAnim>
										<div className="loader"></div>
									</LoadingAnim>
								)}
								{isShotPressed && showModel && dressArrayType === 'tpcs' && (
									<img
										src={tpcsArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tpcl' && (
									<img
										src={tpclArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tpds' && (
									<img
										src={tpdsArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tpdl' && (
									<img
										src={tpdlArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tscs' && (
									<img
										src={tscsArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tscl' && (
									<img
										src={tsclArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tsds' && (
									<img
										src={tsdsArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tsdl' && (
									<img
										src={tsdlArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'olwo' && (
									<img
										src={olwoArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'olwm' && (
									<img
										src={olwmArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'olbo' && (
									<img
										src={olboArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'olbm' && (
									<img
										src={olbmArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'osbm' && (
									<img
										src={osbmArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'osbo' && (
									<img
										src={osboArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'oswm' && (
									<img
										src={oswmArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'oswo' && (
									<img
										src={oswoArrays[dressIndex]}
										alt=""
									/>
								)}
								{isShotPressed && showModel && dressArrayType === 'tpcs' && (
									<img
										src={tpcsArrays[dressIndex]}
										alt=""
									/>
								)}
							</ModelResultContainer>
						</div>
					</>
				)}
				{tab === 2 && (
					<>
						<p>Color & Pattern</p>
					</>
				)}
				<ModelBottomContainer className="flex items-center justify-between mt-2">
					<div className="flex gap-2">
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={() => {
								alertModal('준비중', 'info', () => {})
							}}
							style={{ background: '#bbb' }}
						>
							<img
								src={ToolPin}
								alt=""
								className="mr-4"
							/>
							고정하기
						</button>
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={() => {
								alertModal('준비중', 'info', () => {})
							}}
							style={{ background: '#bbb' }}
						>
							<img
								src={ToolSelect}
								alt=""
								className="mr-4"
							/>
							선택하기
						</button>
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={() => {
								alertModal('준비중', 'info', () => {})
							}}
							style={{ background: '#bbb' }}
						>
							<img
								src={ToolCloth}
								alt=""
								className="mr-4"
							/>
							옷입히기
						</button>
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={() => {
								alertModal('준비중', 'info', () => {})
							}}
							style={{ background: '#bbb' }}
						>
							<img
								src={ToolDraw}
								alt=""
								className="mr-4"
							/>
							직접 그리기
						</button>
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={() => {
								setIsAnimate(true)
							}}
							style={{ background: '#bbb' }}
						>
							<img
								src={ToolDraw}
								alt=""
								className="mr-4"
							/>
							애니메이션
						</button>
					</div>
					<div>
						<button
							type="button"
							className="flex items-center justify-center"
							onClick={handelShotButton}
						>
							<img
								src={ToolShot}
								alt=""
								className="mr-4"
							/>
							촬영하기
						</button>
					</div>
				</ModelBottomContainer>
			</TabContent>
		</div>
	)
}

export default DrawingPage
