import styled from 'styled-components'

const GuideTemplate = styled.div`
	background: #777777;
	min-height: 500px;
`

const GuidePage = () => {
	return (
		<div className="container mx-auto">
			<GuideTemplate className="my-4 p-4"></GuideTemplate>
		</div>
	)
}

export default GuidePage
