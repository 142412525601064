import Swal, { SweetAlertIcon } from 'sweetalert2'

/**
 * alert
 * @param msg
 * @param icon : 'success' | 'error' | 'warning' | 'info' | 'question'
 * @param callback
 */
export const alertModal = (
	msg: string,
	icon: SweetAlertIcon,
	callback: Function
) => {
	Swal.fire({
		html: msg,
		icon: icon,
		confirmButtonText: '확인',
		showCancelButton: false,
		didClose: () => {
			if (callback) callback()
		},
	})
}
