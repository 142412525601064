import styled from 'styled-components'
import Logo from '../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { link } from 'fs'

const HeaderWrapper = styled.header`
	color: var(--color-primary);
	border-bottom: 1px solid var(--color-primary);
	.nav {
	}
	.myinfo {
		border: 2px solid var(--color-primary);
		.itm {
		}
		.username:after {
			content: '';
			display: block;
			width: 1px;
			height: 15px;
			background: var(--color-primary);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}
`

const NavButton = styled(Link)`
	width: 150px;
	line-height: 35px;
	background: #777777;
	color: #fff;
	transition: background 0.3s ease;
	&.active,
	&:hover,
	&:focus,
	&:active {
		background: var(--color-primary);
	}
`

const LogoWrapper = styled.h1`
	width: 60px;
	@media (max-width: 1023px) {
		img {
			width: 40px;
		}
	}
`

const MobileNavButton = styled.button`
	display: none;
	@media (max-width: 1023px) {
		display: block;
	}
`
const MobileMenuWrapper = styled.div`
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	.menuContainer {
		background: #fff;
	}
	&.active {
		opacity: 1;
		visibility: visible;
	}
`
const MobileMenuContainer = styled.div`
	left: 999px;
	transition: all 0.3s ease;
	&.active {
		left: 0;
	}
`

const Header = () => {
	const navigate = useNavigate()
	const [navIsOpen, setNavIsOpen] = useState(false)

	const handleMenuClick = (link: string) => {
		console.log(link)
		if (link) {
			setNavIsOpen(false)
			navigate(link)
		}
	}

	return (
		<HeaderWrapper className="flex items-center justify-between px-5 lg:px-8 py-5">
			<nav className="nav flex items-center">
				<Link
					to="/"
					className="lg:mr-24"
				>
					<LogoWrapper>
						<img
							src={Logo}
							alt=""
						/>
					</LogoWrapper>
				</Link>
				<div className="hidden lg:flex">
					<NavButton
						to="/"
						className="text-xs text-center block active mr-1"
					>
						Drawing note
					</NavButton>
					<NavButton
						to="/community"
						className="text-xs text-center block mr-1"
					>
						Community
					</NavButton>
					<NavButton
						to="/guide"
						className="text-xs text-center block"
					>
						Guide
					</NavButton>
				</div>
			</nav>
			<div className="hidden lg:flex myinfo items-center">
				<p className="relative username text-base itm px-5">
					<b>USER NAME</b>
				</p>
				<p className="point text-base itm px-5">
					10,000 <b>P</b>
				</p>
			</div>
			<MobileNavButton onClick={() => setNavIsOpen(true)}>메뉴</MobileNavButton>
			<MobileMenuWrapper
				className={
					navIsOpen
						? 'fixed active top-0 right-0 bottom-0 left-0'
						: 'fixed top-0 right-0 bottom-0 left-0'
				}
			>
				<MobileMenuContainer
					className={
						navIsOpen
							? 'flex active flex-row justify-end absolute top-0 right-0 bottom-0'
							: 'flex flex-row justify-end absolute top-0 right-0 bottom-0'
					}
				>
					<div className="basis-3/4 p-4 menuContainer">
						<div className="flex justify-end">
							<button
								type="button"
								style={{ marginBottom: '10px' }}
								onClick={() => setNavIsOpen(false)}
							>
								닫기
							</button>
						</div>
						<nav>
							<NavButton
								to=""
								className="text-xs text-center block active mr-1"
								onClick={() => handleMenuClick('/')}
								style={{ width: '100%', marginBottom: '10px' }}
							>
								Drawing note
							</NavButton>
							<NavButton
								to=""
								className="text-xs text-center block mr-1"
								onClick={() => handleMenuClick('/community')}
								style={{ width: '100%', marginBottom: '10px' }}
							>
								Community
							</NavButton>
							<NavButton
								to=""
								className="text-xs text-center block"
								onClick={() => handleMenuClick('/guide')}
								style={{ width: '100%', marginBottom: '10px' }}
							>
								Guide
							</NavButton>
						</nav>
					</div>
				</MobileMenuContainer>
			</MobileMenuWrapper>
		</HeaderWrapper>
	)
}

export default Header
