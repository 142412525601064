import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const TempItem = styled.div`
	background: var(--color-primary);
	position: relative;
	padding-top: 100%;
`

const RecommandPage = () => {
	return (
		<div className="container mx-auto">
			<div className="flex justify-between items-center my-6">
				<h2 className="text-xl">추천의상</h2>
				<Link
					to="/community/category"
					className="color-primary flex items-center"
				>
					<span className="mr-4">All image</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="27.713"
						viewBox="0 0 24 27.713"
					>
						<path
							id="패스_403"
							data-name="패스 403"
							d="M5447,149l-24-13.856v27.713Z"
							transform="translate(-5423 -135.144)"
							fill="#702e75"
						/>
					</svg>
				</Link>
			</div>
			<div className="grid grid-cols-4 gap-2">
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
				<TempItem></TempItem>
			</div>
		</div>
	)
}

export default RecommandPage
