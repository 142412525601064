import { Suspense } from 'react'

import { Routes, Route } from 'react-router-dom'

import DefaultLayout from './layouts/defaultLayout'

import LandingPage from './pages/landing'
import RecommandPage from './pages/community/recommand'
import GuidePage from './pages/guide'

import DrawingPage from './pages/drawing'

function App() {
	return (
		<Suspense fallback={<div>Loading</div>}>
			<Routes>
				<Route
					path="/"
					element={
						<DefaultLayout>
							<LandingPage />
						</DefaultLayout>
					}
				/>
				<Route
					path="/community"
					element={
						<DefaultLayout>
							<RecommandPage />
						</DefaultLayout>
					}
				/>
				<Route
					path="/guide"
					element={
						<DefaultLayout>
							<GuidePage />
						</DefaultLayout>
					}
				/>
				<Route
					path="/drawing"
					element={
						<DefaultLayout>
							<DrawingPage />
						</DefaultLayout>
					}
				/>
			</Routes>
		</Suspense>
	)
}

export default App
