import Header from '../components/header'

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window
	children: React.ReactElement
}

const DefaultLayout = ({ children }: Props) => {
	return (
		<>
			<Header />
			{children}
		</>
	)
}

export default DefaultLayout
