import { useState } from 'react'
import {
	DrawContainer,
	DrawItem,
	DrawItemNote,
	PageTitleBar,
} from '../../components/styles'

import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useNavigate } from 'react-router-dom'

import Img1 from '../../assets/img/landing/1.png'
import Img2 from '../../assets/img/landing/2.png'
import Img3 from '../../assets/img/landing/3.png'
import Img4 from '../../assets/img/landing/4.png'
import Img5 from '../../assets/img/landing/5.png'

const BannerWrapper = styled.div`
	width: 830px;
	z-index: 1;
	@media (max-width: 1023px) {
		width: 100%;
	}
`

const settings = {
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	autoplay: true,
}

const LandingPage = () => {
	const navigate = useNavigate()
	const [isNoteOpen, setIsNoteOpen] = useState(false)
	const [openNoteId, setOpenNoteId] = useState(0)

	const onNoteClick = (e: number) => {
		navigate('/drawing')
		// if (openNoteId === e) {
		// 	setIsNoteOpen(!isNoteOpen)
		// 	setOpenNoteId(0)
		// 	return
		// } else {
		// 	setIsNoteOpen(true)
		// 	setOpenNoteId(e)
		// }
	}

	return (
		<div className="relative">
			<div className="container mx-auto relative">
				<PageTitleBar>
					<h2 className="text-xl py-9 color-primary pl-20">
						<b>Drawing note</b>
					</h2>
				</PageTitleBar>
				<DrawContainer className="pt-10 relative">
					<DrawItem onClick={() => onNoteClick(1)}></DrawItem>
				</DrawContainer>
				<DrawItemNote
					className={
						isNoteOpen ? 'absolute top-0 right-0 active' : 'absolute top-0 right-0'
					}
				>
					<h3 className="noteName text-7xl p-11">
						<b>Note name</b>
					</h3>
					<div className="grid grid-cols-3 gap-2 p-5">
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
						<div className="drawItem"></div>
					</div>
				</DrawItemNote>
			</div>
			<BannerWrapper className="relative lg:absolute lg:top-0 lg:right-0">
				<Slider {...settings}>
					<img
						src={Img1}
						alt=""
					/>
					<img
						src={Img2}
						alt=""
					/>
					<img
						src={Img3}
						alt=""
					/>
					<img
						src={Img4}
						alt=""
					/>
					<img
						src={Img5}
						alt=""
					/>
				</Slider>
			</BannerWrapper>
		</div>
	)
}

export default LandingPage
