import styled from 'styled-components'
import ItemBg from '../assets/img/item.png'
import ItemBgHover from '../assets/img/itemHover.png'

export const PageTitleBar = styled.div`
	border-bottom: 1px solid var(--color-primary);
`

export const DrawContainer = styled.div`
	width: 800px;
	// margin-left: auto;
	// margin-right: auto;
	@media (max-width: 1023px) {
		width: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
`

export const DrawItem = styled.button`
	width: 350px;
	height: 440px;
	background-image: url(${ItemBg});
	background-size: 350px 440px;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: 9px;
	margin-right: 9px;
	margin-bottom: 18px;
	&:hover {
		background: url(${ItemBgHover});
		background-size: 350px 440px;
		background-repeat: no-repeat;
		background-position: center;
	}
	@media (max-width: 1023px) {
	}
`
export const DrawItemNote = styled.div`
	display: none;
	background: var(--color-primary);
	color: var(--color-white);
	z-index: 2;
	.drawItem {
		background: #000;
		position: relative;
		padding-top: 100%;
		&:after {
			content: '';
		}
	}
	&.active {
		display: block;
	}
`
